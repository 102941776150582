@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

/*
Theme Name: KOMORI Toshiaki 2020
Theme URI: http://wordpress.org/extend/themes/twentyeleven
Author: ts5h
Description: The theme for KOMORI Toshiaki 2020
*/

/* =Reset default browser CSS. Based on work by Eric Meyer: http://meyerweb.com/eric/tools/css/reset/index.html
-------------------------------------------------------------- */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

:focus { /* remember to define focus styles! */
    outline: 0;
}

body {
    background: #fff;
}

ol, ul {
    list-style: none;
}

table { /* tables still need 'cellspacing="0"' in the markup */
    border-collapse: separate;
    border-spacing: 0;
}

caption, th, td {
    font-weight: normal;
    text-align: left;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: "";
}

blockquote, q {
    quotes: "" "";
}

a img {
    border: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}



.clear {
    clear: both;
}

.rmv {
    display: block;
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.rmv-t {
    text-indent: -9999px;
}




/* Common */
a {
    word-break: break-all;
}

p.no_margin {
    margin: 0;
}

p.center {
    text-align: center;
}

p.right {
    text-align: right;
}




#titleLesson {
    background: url(/img/lesson_s.png) no-repeat top left;
    width: 153px;
    height: 16px;
    margin-bottom: 10px;
}

a.more {
    display: block;
    float: right;

    width: 52px;
    height: 15px;
    margin-left: 10px;

    text-indent: -9999px;
}

a.more:link, a.more:visited, a.more:active {
    background: url(/img/more.jpg) no-repeat top left;
}

a.more:hover, a.more:visited:hover {
    background: url(/img/more_hover.jpg) no-repeat top left;
    text-decoration: none;
    border: 0;
}


/*
HOME MORE
2017/03/04
*/

a.link_home {
    background: url(/img/more.jpg) no-repeat top 2px right;
    color: #444;
    display: block;
    font-weight: bold;
    text-decoration: none;
}

a.link_home:hover {
    background-image: url(/img/more_hover.jpg);
}


/* ---------- PAGE UP ---------- */

.pageup {
    display: block;
    width: 111px;
    height: 30px;
    margin-bottom: 30px;
    text-indent: -9999px;
    float: right;
}

a.pageup:link, a.pageup:active, a.pageup:visited {
    background: url(/img/pageup.png) no-repeat right center;
}

a.pageup:hover, a.pageup:visited:hover {
    background: url(/img/pageup_hover.png) no-repeat right center;
}

a.fblink:link, a.fblink:active, a.fblink:visited {
    color: #777;
}

a.fblink:hover, a.fblink:visited:hover, a.fblink:focus {
    color: #3c5a98;
}

/* ---------- BACK ---------- */

.pageback {
    display: block;
    width: 65px;
    height: 30px;
    margin-bottom: 30px;
    text-indent: -9999px;
    float: left;
}

a.pageback:link, a.pageback:active, a.pageback:visited {
    background: url(/img/back.png) no-repeat right center;
}

a.pageback:hover, a.pageback:visited:hover {
    background: url(/img/back_hover.png) no-repeat right center;
}



/* ---------- ARCHIVES MENU ---------- */

#archivesConcert, #archivesPublishment, #archivesBooks, #archivesStream {
    display: block;
    width: 100px;
    height: 20px;
    margin-bottom: 30px;
    text-indent: -9999px;
}

a#archivesConcert:link, a#archivesConcert:active, a#archivesConcert:visited {
    background: url(/img/concert.png) no-repeat left center;
}

a#archivesConcert:hover, a#archivesConcert:visited:hover {
    background: url(/img/concert_hover.png) no-repeat left center;
}

a#archivesPublishment:link, a#archivesPublishment:active, a#archivesPublishment:visited {
    background: url(/img/publishment.png) no-repeat left center;
}

a#archivesPublishment:hover, a#archivesPublishment:visited:hover {
    background: url(/img/publishment_hover.png) no-repeat left center;
}

a#archivesBooks:link, a#archivesBooks:active, a#archivesBooks:visited {
    background: url(/img/books.png) no-repeat left center;
}

a#archivesBooks:hover, a#archivesBooks:visited:hover {
    background: url(/img/books_hover.png) no-repeat left center;
}

a#archivesStream:link, a#archivesStream:active, a#archivesStream:visited {
    background: url(/img/stream.png) no-repeat left center;
}

a#archivesStream:hover, a#archivesStream:visited:hover {
    background: url(/img/stream_hover.png) no-repeat left center;
}


/* Structure
----------------------------------------------- */

header {
    position: relative;
    /* width:100%;*/
    width: auto;
    padding-top: 44px;
}

#page {
    position: relative;
    margin: 0 auto;
    max-width: 1000px;
}

#branding hgroup {
}

#access div {
}


#main {
    clear: both;
    position: absolute;
    top: 175px;
    left: 0;
    width: 100%;
    z-index: 1;
}

#main_home {
    clear: both;
    position: absolute;
    top: 130px;
    left: 0;
    width: 100%;
    z-index: 1;
}


#primary {
    position: relative;
    top: 0;
    left: 310px;
    width: 600px;
}

#content {
}

#secondary {
    position: relative;
    top: 0;
    left: 0px;
    width: 230px;
}

/* Singular */
.singular #primary {
    margin: 0;
}

.singular #content,
.left-sidebar.singular #content {
    position: relative;
}

.singular .entry-header,
.singular .entry-content,
.singular footer.entry-meta,
.singular #comments-title {
    margin: 0 auto;
}

/* Attachments */
.singular .image-attachment .entry-content {
    margin: 0 auto;
    width: auto;
}

.singular .image-attachment .entry-description {
    margin: 0 auto;
}

/* Showcase */
.page-template-showcase-php #primary,
.left-sidebar.page-template-showcase-php #primary {
    margin: 0;
}

.page-template-showcase-php #content,
.left-sidebar.page-template-showcase-php #content {
    margin: 0;
}

.page-template-showcase-php section.recent-posts {
    float: right;
}

.page-template-showcase-php #main .widget-area {
    float: left;
}

/* error404 */
.error404 #primary {
}

.error404 #primary #content {
}

/* Alignment */
.alignleft {
    display: inline;
    float: left;
    margin-right: 1.625em;
}

.alignright {
    display: inline;
    float: right;
    margin-left: 1.625em;
}

.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


/* Right Content */
.left-sidebar #primary {
    z-index: 1;
    position: relative;
    width: 600px;
    top: 0;
    left: -50px;
    float: right;
}

.left-sidebar #content {
}

.left-sidebar #secondary {
    z-index: 3;
    position: relative;
    float: left;
    top: 0;
    left: 0px;
    width: 230px;
}




/* One column */
.one-column #page {
    max-width: 690px;
}

.one-column #content {
    width: auto;
}

.one-column #nav-below {
    border-bottom: 1px dotted #ddd;
    margin-bottom: 1.625em;
}

.one-column #secondary {
    width: auto;
}

/* Simplify the showcase template */
.one-column .page-template-showcase-php section.recent-posts {
    float: none;
    margin: 0;
    width: 100%;
}

.one-column .page-template-showcase-php #main .widget-area {
    float: none;
    margin: 0;
    width: auto;
}

.one-column .page-template-showcase-php .other-recent-posts {
    border-bottom: 1px dotted #ddd;
}

/* Simplify the showcase template when small feature */
.one-column section.featured-post .attachment-small-feature {
    border: none;
    display: block;
    height: auto;
    max-width: 60%;
    position: static;
}

.one-column article.feature-image.small {
    margin: 0 0 1.625em;
    padding: 0;
}

.one-column article.feature-image.small .entry-title {
    font-size: 20px;
    line-height: 1.3em;
}

.one-column article.feature-image.small .entry-summary {
    height: 150px;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
}

.one-column article.feature-image.small .entry-summary a {
    left: -9%;
}

/* Remove the margin on singular articles */
.one-column.singular .entry-header,
.one-column.singular .entry-content,
.one-column.singular footer.entry-meta,
.one-column.singular #comments-title {
    width: 100%;
}

/* Simplify the pullquotes and pull styles */
.one-column.singular blockquote.pull {
    margin: 0 0 1.625em;
}

.one-column.singular .pull.alignleft {
    margin: 0 1.625em 0 0;
}

.one-column.singular .pull.alignright {
    margin: 0 0 0 1.625em;
}

.one-column.singular .entry-meta .edit-link a {
    position: absolute;
    left: 0;
    top: 40px;
}

.one-column.singular #author-info {
    margin: 2.2em -8.8% 0;
    padding: 20px 8.8%;
}

/* Make sure we have room for our comment avatars */
.one-column .commentlist > li.comment {
    margin-left: 102px;
    width: auto;
}

/* Make sure the logo and search form don't collide */
.one-column #branding #searchform {
    right: 40px;
    top: 4em;
}

/* Talking avatars take up too much room at this size */
.one-column .commentlist > li.comment {
    margin-left: 0;
}

.one-column .commentlist > li.comment .comment-meta,
.one-column .commentlist > li.comment .comment-content {
}

.one-column .commentlist .avatar {
    background: transparent;
    display: block;
    padding: 0;
    top: 1.625em;
    left: auto;
    right: 1.625em;
}

.one-column .commentlist .children .avatar {
    background: none;
    padding: 0;
    position: absolute;
    top: 2.2em;
    left: 2.2em;
}

.one-column #respond {
    width: auto;
}


/* =Global
----------------------------------------------- */

body, input, textarea {
    color: #363636;
    /*font: 15px "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 16px;
    font-weight: 200;
    line-height: 175%;
    letter-spacing: 0.1px;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
    clear: both;
}

hr {
    border-color: #ccc;
    border-width: 0 0 1px 0;
    height: 0px;
    border-style: dotted;
}

h4 {
    font-weight: bold;
    font-size: 20px;
    color: #aaa;
}

/* Text elements */
p {
    margin-bottom: 15px;
}

ul, ol {
    margin: 0 0 1.625em 2.5em;
}

ul {
    list-style: square;
}

ol {
    list-style-type: decimal;
}

ol ol {
    list-style: upper-alpha;
}

ol ol ol {
    list-style: lower-roman;
}

ol ol ol ol {
    list-style: lower-alpha;
}

ul ul, ol ol, ul ol, ol ul {
    margin-bottom: 0;
}

dl {
    margin: 0 1.625em;
}

dt {
    font-weight: bold;
}

dd {
    margin-bottom: 1.625em;
}

strong {
    font-weight: bold;
}

cite, em, i {
    font-style: italic;
}

blockquote {
    font-family: Georgia, "Bitstream Charter", serif;
    font-style: italic;
    font-weight: normal;
    margin: 0 3em;
}

blockquote em, blockquote i, blockquote cite {
    font-style: normal;
}

blockquote cite {
    color: #666;
    /*font: 12px "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

pre {
    background: #f4f4f4;
    font: 13px "Courier 10 Pitch", Courier, monospace;
    line-height: 1.5;
    margin-bottom: 1.625em;
    overflow: auto;
    padding: 0.75em 1.625em;
}

code, kbd {
    font: 13px Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}

abbr, acronym, dfn {
    border-bottom: 1px dotted #666;
    cursor: help;
}

address {
    display: block;
    margin: 0 0 1.625em;
}

ins {
    background: #fff9c0;
    text-decoration: none;
}

sup,
sub {
    font-size: 10px;
    height: 0;
    line-height: 1;
    position: relative;
    vertical-align: baseline;
}

sup {
    bottom: 1ex;
}

sub {
    top: .5ex;
}

/* Forms */
input[type=text],
input[type=email],
input[type=password],
textarea {
    background: #fafafa;
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
    border: 1px solid #888;
    color: #888;
}

input[type=text]:focus,
input[type=email]:focus,
textarea:focus {
    color: #373737;
}

textarea {
    padding-left: 3px;
    width: 98%;
}

input[type=text],
input[type=email] {
    padding: 3px;
}

input#s {
    background: url(images/search.png) no-repeat 5px 6px;
    font-size: 14px;
    height: 22px;
    line-height: 1.2em;
    padding: 4px 10px 4px 28px;
}

input#searchsubmit {
    display: none;
}

/*
	2015/02/09
	「送信」ボタン
*/
input[type=submit] {
    /*width: 100px;*/
    padding: 5px;
}

.wpcf7-submit {
    width: 90px;
}


/* Links */
a {

    text-decoration: none;
}

a:focus,
a:active,
a:hover {
    text-decoration: none;
}

/* Assistive text */
.assistive-text {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

#access a.assistive-text:active,
#access a.assistive-text:focus {
    border-bottom: 1px solid #ddd;
    clip: auto !important;
    font-size: 12px;
    position: absolute;
    text-decoration: none;
    top: 0;
    left: 7.6%;
}


/* =Header
----------------------------------------------- */

#branding {
    padding-bottom: 10px;
    position: relative;
    z-index: 9999;
    height: 80px;
}

#site-title {
    width: 568px;
    height: 59px;
    text-indent: -9999px;
    background: url(/img/logo.png) no-repeat left top;
}

#site-title a {
    display: block;
    width: 568px;
    height: 59px;
    text-decoration: none;
    color: #111;
    font-size: 40px;
    font-weight: bold;
    text-decoration: none;
}


#site-title a:hover,
#site-title a:focus,
#site-title a:active {
    color: #1982d1;
}

#site-description {
    color: #7a7a7a;
    font-size: 14px;
    margin: 0 270px 3.65625em 0;
}

#branding img {
    height: auto;
    margin-bottom: -7px;
    width: 100%;
}


/* =Menu
-------------------------------------------------------------- */

#access {
    background: #222; /* Show a solid color for older browsers */
    background: -moz-linear-gradient(#252525, #0a0a0a);
    background: -o-linear-gradient(#252525, #0a0a0a);
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#252525), to(#0a0a0a)); /* older webkit syntax */
    background: -webkit-linear-gradient(#252525, #0a0a0a);
    -webkit-box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 2px;
    -moz-box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 2px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 2px;
    clear: both;
    display: block;
    float: left;
    margin: 0 auto 6px;
    width: 100%;
}

#access ul {
    font-size: 13px;
    list-style: none;
    margin: 0 0 0 -0.8125em;
    padding-left: 0;
}

#access li {
    float: left;
    position: relative;
}

#access a {
    color: #eee;
    display: block;
    line-height: 3.333em;
    padding: 0 1.2125em;
    text-decoration: none;
}

#access ul ul {
    -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    display: none;
    float: left;
    margin: 0;
    position: absolute;
    top: 3.333em;
    left: 0;
    width: 188px;
    z-index: 99999;
}

#access ul ul ul {
    left: 100%;
    top: 0;
}

#access ul ul a {
    background: #f9f9f9;
    border-bottom: 1px dotted #ddd;
    color: #444;
    font-size: 13px;
    font-weight: normal;
    height: auto;
    line-height: 1.4em;
    padding: 10px 10px;
    width: 168px;
}

#access li:hover > a,
#access ul ul :hover > a,
#access a:focus {
    background: #efefef;
}

#access li:hover > a,
#access a:focus {
    background: #f9f9f9; /* Show a solid color for older browsers */
    background: -moz-linear-gradient(#f9f9f9, #e5e5e5);
    background: -o-linear-gradient(#f9f9f9, #e5e5e5);
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#f9f9f9), to(#e5e5e5)); /* Older webkit syntax */
    background: -webkit-linear-gradient(#f9f9f9, #e5e5e5);
    color: #373737;
}

#access ul li:hover > ul {
    display: block;
}

#access .current-menu-item > a,
#access .current-menu-ancestor > a,
#access .current_page_item > a,
#access .current_page_ancestor > a {
    font-weight: bold;
}

/* Search Form */
#branding #searchform {
    position: absolute;
    top: 3.8em;
    right: 7.6%;
    text-align: right;
}

#branding #searchform div {
    margin: 0;
}

#branding #s {
    float: right;
    -webkit-transition-duration: 400ms;
    -webkit-transition-property: width, background;
    -webkit-transition-timing-function: ease;
    -moz-transition-duration: 400ms;
    -moz-transition-property: width, background;
    -moz-transition-timing-function: ease;
    -o-transition-duration: 400ms;
    -o-transition-property: width, background;
    -o-transition-timing-function: ease;
    width: 72px;
}

#branding #s:focus {
    background-color: #f9f9f9;
    width: 196px;
}

#branding #searchsubmit {
    display: none;
}

#branding .only-search #searchform {
    top: 5px;
    z-index: 1;
}

#branding .only-search #s {
    background-color: #666;
    border-color: #000;
    color: #222;
}

#branding .only-search #s,
#branding .only-search #s:focus {
    width: 85%;
}

#branding .only-search #s:focus {
    background-color: #bbb;
}

#branding .with-image #searchform {
    top: auto;
    bottom: -27px;
    max-width: 195px;
}

#branding .only-search + #access div {
    padding-right: 205px;
}


/* =Content
----------------------------------------------- */

h5.title_archive {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
}

.page-title {
    color: #888;
    font-size: 14px;
    margin: 0;
    text-transform: uppercase;
    border-bottom: 1px dotted #ddd;
}

.page-title a {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    text-transform: none;
}

.hentry,
.no-results {
    border-bottom: 1px dotted #ddd;

    margin: 0 0 1.625em;
    padding: 0 0 1.625em;

    position: relative;
}

.hentry:last-child,
.no-results {
    border-bottom: none;
}

/*
	2015/02/09
	NEWS & INFO., BLOG一覧
*/
.list_content .hentry {
    margin: -1.8em 0 1.625em 0 !important;
    padding: 0 0 2.5em 0 !important;
}

.blog .sticky .entry-header .entry-meta {
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
}

.entry-title,
.entry-header .entry-meta {
    padding-right: 20px;
}

.entry-title {
    clear: both;
    color: #666;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5em;
}

.entry-title,
.entry-title a {
    color: #777;
    text-decoration: none;
}

.entry-title a:hover,
.entry-title a:focus,
.entry-title a:active {
    color: #1982d1;
}

.entry-meta {
    margin-top: 5px;
    color: #666;
    clear: both;
    font-size: 11px;
    line-height: 18px;
}

.entry-meta a {
    font-weight: bold;
}

.single-author .entry-meta .by-author {
    display: none;
}

.entry-content,
.entry-summary {
}

.entry-content h1,
.entry-content h2,
.comment-content h1,
.comment-content h2 {
    color: #666;
    font-weight: bold;
    margin: 0 0 .8125em;
}

.entry-content h3,
.comment-content h3 {
    font-size: 10px;
    letter-spacing: 0.1em;
    line-height: 2.6em;
    text-transform: uppercase;
}

.entry-content table,
.comment-content table {
    border-bottom: 1px dotted #ddd;
    margin: 0 0 1.625em;
    width: 100%;
}

.entry-content th,
.comment-content th {
    color: #666;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 2.6em;
    text-transform: uppercase;
}

.entry-content td,
.comment-content td {
    border-top: 1px dotted #ddd;
    padding: 6px 10px 6px 0;
}

.entry-content #s {
}

.comment-content ul,
.comment-content ol {
    margin-bottom: 1.625em;
}

.comment-content ul ul,
.comment-content ol ol,
.comment-content ul ol,
.comment-content ol ul {
    margin-bottom: 0;
}

dl.gallery-item {
    margin: 0;
}

.page-link {
    clear: both;
    display: block;
    margin: 0 0 1.625em;
}

.page-link a {
    background: #eee;
    color: #373737;
    margin: 0;
    padding: 2px 3px;
    text-decoration: none;
}

.page-link a:hover {
    background: #888;
    color: #fff;
    font-weight: bold;
}

.page-link span {
    margin-right: 6px;
}

.entry-meta .edit-link a,
.commentlist .edit-link a {
    background: #eee;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #666;
    position: absolute;
    right: 0px;
    font-size: 12px;
    line-height: 1.5em;
    font-weight: 300;
    text-decoration: none;
    padding: 0 8px;
}

.entry-meta .edit-link a:hover,
.commentlist .edit-link a:hover {
    background: #888;
    color: #fff;
}

.entry-content .edit-link {
    clear: both;
    display: block;
}

/* Images */
.entry-content img,
.comment-content img,
.widget img {
    max-width: 97.5%; /* Fluid images for posts, comments, and widgets */
}

img[class*="align"],
img[class*="wp-image-"],
img[class*="attachment-"] {
    height: auto; /* Make sure images with WordPress-added height and width attributes are scaled correctly */
}

img.size-full,
img.size-large {
    max-width: 97.5%;
    width: auto; /* Prevent stretching of full-size and large-size images with height and width attributes in IE8 */
    height: auto; /* Make sure images with WordPress-added height and width attributes are scaled correctly */
}

.entry-content img.wp-smiley {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
}

img.alignleft,
img.alignright,
img.aligncenter {
    margin-bottom: 1.625em;
}

p img,
.wp-caption {
    margin-top: 0.4em;
}

.wp-caption {
}

.wp-caption img {
    display: block;
    margin: 0 auto;
    max-width: 98%;
}

.wp-caption .wp-caption-text,
.gallery-caption {
    color: #666;
    font-size: 12px;
}

.wp-caption .wp-caption-text {
    padding-top: 4px;
    position: relative;
}

.wp-caption .wp-caption-text:before {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    margin-right: 5px;
    position: absolute;
    left: 10px;
    top: 7px;
}

#content .gallery {

}

#content .gallery a img {
    border: none;
}

img#wpstats {
    display: block;
    margin: 0 auto 1.625em;
}

#content .gallery-columns-4 .gallery-item {
    width: 23%;
    padding-right: 2%;
}

#content .gallery-columns-4 .gallery-item img {
    width: 100%;
    height: auto;
}

/* Image borders */
img[class*="align"],
img[class*="wp-image-"],
#content .gallery .gallery-icon img { /* Add fancy borders to all WordPress-added images but not things like badges and icons and the like */
}

.wp-caption img {
}

a:focus img[class*="align"],
a:hover img[class*="align"],
a:active img[class*="align"],
a:focus img[class*="wp-image-"],
a:hover img[class*="wp-image-"],
a:active img[class*="wp-image-"],
#content .gallery .gallery-icon a:focus img,
#content .gallery .gallery-icon a:hover img,
#content .gallery .gallery-icon a:active img { /* Add some useful style to those fancy borders for linked images ... */
    background: #eee;
    border-color: #bbb;
}

.wp-caption a:focus img,
.wp-caption a:active img,
.wp-caption a:hover img { /* ... including captioned images! */
    background: #fff;
    border-color: #ddd;
}

/* Make sure embeds and iframes fit their containers */
embed,
iframe,
object {
    max-width: 100%;
}

/* Password Protected Posts */
.post-password-required .entry-header .comments-link {
    margin: 1.625em 0 0;
}

.post-password-required input[type=password] {
    margin: 0.8125em 0;
}

.post-password-required input[type=password]:focus {
    background: #f7f7f7;
}

/* Author Info */
#author-info {
    font-size: 12px;
    overflow: hidden;
}

.singular #author-info {
    background: #f9f9f9;
    border-top: 1px dotted #ddd;
    border-bottom: 1px dotted #ddd;
    margin: 2.2em -35.6% 0 -35.4%;
    padding: 20px 35.4%;
}

.archive #author-info {
    border-bottom: 1px dotted #ddd;
    margin: 0 0 2.2em;
    padding: 0 0 2.2em;
}

#author-avatar {
    float: left;
    margin-right: -78px;
}

#author-avatar img {
    background: #fff;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 2px #bbb;
    -moz-box-shadow: 0 1px 2px #bbb;
    box-shadow: 0 1px 2px #bbb;
    padding: 3px;
}

#author-description {
    float: left;
    margin-left: 108px;
}

#author-description h2 {
    color: #000;
    font-size: 15px;
    font-weight: bold;
    margin: 5px 0 10px;
}

/* Comments link */

/*
	2015/02/09
	Atsushi Yamazaki
	背景にFlashが重なったときの表示処理追加
*/
.entry-header .comments-link a {
    color: #666;
    font-size: 12px;
    font-weight: normal;
    line-height: 41px;
    text-align: center;
    text-decoration: none;

    background: url(images/comment-bubble.png) no-repeat;
    overflow: hidden;

    margin: 0;
    padding: 0;

    position: absolute;
    top: 5px;
    right: -5px;
    width: 52px;
    height: 45px;
}

.entry-header .comments-link a:hover,
.entry-header .comments-link a:focus,
.entry-header .comments-link a:active {
    color: #fff;

    background-color: transparent !important;
    background-image: url(images/comment-bubble_hover.png);
    background-repeat: no-repeat;
}

.entry-header .comments-link .leave-reply {
    visibility: hidden;
}

/*
.entry-header .comments-link a {
	background: #eee url(images/comment-bubble.png) no-repeat;
	color: #666;
	font-size: 13px;
	font-weight: normal;
	line-height: 35px;
	overflow: hidden;
	padding: 0 0 0;
	position: absolute;
	top: 0;
	right: 0;
	text-align: center;
	text-decoration: none;
	width: 43px;
	height: 36px;
}
.entry-header .comments-link a:hover,
.entry-header .comments-link a:focus,
.entry-header .comments-link a:active {
	background-color: #1982d1;
	color: #fff;
	color: rgba(255,255,255,0.8);
}
.entry-header .comments-link .leave-reply {
	visibility: hidden;
}
*/

/*
Post Formats Headings
To hide the headings, display: none the ".entry-header .entry-format" selector,
and remove the padding rules below.
*/
.entry-header .entry-format {
    color: #666;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 2.6em;
    position: absolute;
    text-transform: uppercase;
    top: -5px;
}

.entry-header hgroup .entry-title {
}

article.format-aside .entry-content,
article.format-link .entry-content,
article.format-status .entry-content {
}

article.format-status .entry-content {
}

.recent-posts .entry-header .entry-format {
    display: none;
}

.recent-posts .entry-header hgroup .entry-title {
    padding-top: 0;
}

/* Singular content styles for Posts and Pages */
.singular .hentry {
    border-bottom: none;
    padding: 4.875em 0 2em 0;
    position: relative;
}

.singular.page .hentry {
    padding: 3.5em 0 0;
}

.singular .entry-title {
    color: #222;
    font-size: 22px;
    font-weight: bold;
}

.singular .entry-title,
.singular .entry-header .entry-meta {
    padding-right: 0;
}

.singular .entry-header .entry-meta {
    position: absolute;
    top: 0;
    left: 0;
}

blockquote.pull {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.6125em;
    margin: 0 0 1.625em;
    text-align: center;
}

.singular blockquote.pull {
    margin: 0 -22.25% 1.625em;
}

.pull.alignleft {
    margin: 0 1.625em 0 0;
    text-align: right;
    width: 33%;
}

.singular .pull.alignleft {
    margin: 0 1.625em 0 -22.25%;
}

.pull.alignright {
    margin: 0 0 0 1.625em;
    text-align: left;
    width: 33%;
}

.singular .pull.alignright {
    margin: 0 -22.25% 0 1.625em;
}

.singular blockquote.pull.alignleft,
.singular blockquote.pull.alignright {
    width: 33%;
}

.singular .entry-meta .edit-link a {
    bottom: auto;
    left: 50px;
    position: absolute;
    right: auto;
    top: 80px;
}



/*
	LESSON
	2017/02/25
	Atsushi Yamazaki
*/

div.block-lesson h2.entry-title {
    color: #222;
    font-size: 22px;
    margin-bottom: 25px;
}

div.block-lesson p {
    margin-bottom: 25px;
}

div.block-lesson dl {
    margin: 0.8125em 0;
    padding-left: 2em;
}

div.block-lesson dt.bullet {
    display: list-item;
    font-size: 18px;
    list-style: disc outside;
    margin-bottom: 5px;
}

div.block-lesson dd {
    margin-bottom: 25px;
}

/* URL */
a.url {
    word-break: break-all;
}


/* Global Navigation -------------------------------------------*/

nav#global {
    position: absolute;
    left: 0;
    margin-top: 20px;
    width: 230px;
    height: 380px;
    margin-bottom: 21px;
    z-index: 100;
}

nav#global ul {
    width: 230px;
    height: 380px;
    margin: 0 auto;
    position: relative;
}

nav#global ul li {
    line-height: normal;
    position: absolute;
    padding: 0px;
    margin: 0px;
    left: 0px;
}

nav#global ul li, nav#global ul li a {
    width: 230px;
    height: 30px;
    display: block;
    overflow: hidden;
    font-size: large;
    font-family: "Helvetica", "Arial", "ヒラギノ角ゴPro W3", "MS Pゴシック";
    font-weight: normal;
    text-decoration: none;
}

nav#global ul li a:link, nav#global ul li a:visited, nav#global ul li a:active {
    color: #888;
}

nav#global ul li a.selected {
    color: #1982d1;
}

nav#global ul li a {
    text-indent: -9999px;
    text-decoration: none;
    background-repeat: no-repeat;
    border: none;
}

nav#global ul li.menuHome {
    top: 0px;
}

nav#global ul li.menuNews {
    top: 50px;
}

nav#global ul li.menuProfile {
    top: 100px;
}

nav#global ul li.menuWorks {
    top: 150px;
}

nav#global ul li.menuArchives {
    top: 200px;
}

nav#global ul li.menuLesson {
    top: 250px;
}

nav#global ul li.menuBlog {
    top: 300px;
}

nav#global ul li.menuContact {
    top: 350px;
}


.menuHome a:link, .menuHome a:active, .menuHome a:visited {
    background: url(/img/menu.png) 0px 0px;
}

.menuNews a:link, .menuNews a:active, .menuNews a:visited {
    background: url(/img/menu.png) 0px -50px;
}

.menuProfile a:link, .menuProfile a:active, .menuProfile a:visited {
    background: url(/img/menu.png) 0px -100px;
}

.menuWorks a:link, .menuWorks a:active, .menuWorks a:visited {
    background: url(/img/menu.png) 0px -150px;
}

.menuArchives a:link, .menuArchives a:active, .menuArchives a:visited {
    background: url(/img/menu.png) 0px -200px;
}

.menuLesson a:link, .menuLesson a:active, .menuLesson a:visited {
    background: url(/img/menu.png) 0px -250px;
}

.menuBlog a:link, .menuBlog a:active, .menuBlog a:visited {
    background: url(/img/menu.png) 0px -300px;
}

.menuContact a:link, .menuContact a:active, .menuContact a:visited {
    background: url(/img/menu.png) 0px -350px;
}

.menuHome a:hover, .menuHome a:visited:hover, .menuHome a.selected:link, .menuHome a.selected:visited {
    background: url(/img/menu.png) -230px 0px;
}

.menuNews a:hover, .menuNews a:visited:hover, .menuNews a.selected:link, .menuNews a.selected:visited {
    background: url(/img/menu.png) -230px -50px;
}

.menuProfile a:hover, .menuProfile a:visited:hover, .menuProfile a.selected:link, .menuProfile a.selected:visited {
    background: url(/img/menu.png) -230px -100px;
}

.menuWorks a:hover, .menuWorks a:visited:hover, .menuWorks a.selected:link, .menuWorks a.selected:visited {
    background: url(/img/menu.png) -230px -150px;
}

.menuArchives a:hover, .menuArchives a:visited:hover, .menuArchives a.selected:link, .menuArchives a.selected:visited {
    background: url(/img/menu.png) -230px -200px;
}

.menuLesson a:hover, .menuLesson a:visited:hover, .menuLesson a.selected:link, .menuLesson a.selected:visited {
    background: url(/img/menu.png) -230px -250px;
}

.menuBlog a:hover, .menuBlog a:visited:hover, .menuBlog a.selected:link, .menuBlog a.selected:visited {
    background: url(/img/menu.png) -230px -300px;
}

.menuContact a:hover, .menuContact a:visited:hover, .menuContact a.selected:link, .menuContact a.selected:visited {
    background: url(/img/menu.png) -230px -350px;
}


/* Social button */
#social {
    line-height: 1em;
    margin-bottom: 20px;
    width: 230px;
    position: absolute;
    top: 425px;
    left: 0;
    z-index: 200;
    display: block;
}

/* =Aside
----------------------------------------------- */

.format-aside .entry-title,
.format-aside .entry-header .comments-link {
    display: none;
}

.singular .format-aside .entry-title {
    display: block;
}

.format-aside .entry-content {
    padding: 0;
}

.singular .format-aside .entry-content {
}


/* =Link
----------------------------------------------- */

.format-link .entry-title,
.format-link .entry-header .comments-link {
    display: none;
}

.singular .format-link .entry-title {
    display: block;
}

.format-link .entry-content {
    padding: 0;
}

.singular .format-link .entry-content {
}


/* =Gallery
----------------------------------------------- */

.format-gallery .gallery-thumb {
    float: left;
    display: block;
    margin: .375em 1.625em 0 0;
}


/* =Status
----------------------------------------------- */

.format-status .entry-title,
.format-status .entry-header .comments-link {
    display: none;
}

.singular .format-status .entry-title {
    display: block;
}

.format-status .entry-content {
    padding: 0;
}

.singular .format-status .entry-content {
}

.format-status img.avatar {
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 2px #ccc;
    -moz-box-shadow: 0 1px 2px #ccc;
    box-shadow: 0 1px 2px #ccc;
    float: left;
    margin: 4px 10px 2px 0;
    padding: 0;
}


/* =Quote
----------------------------------------------- */

.format-quote blockquote {
    color: #555;
    font-size: 17px;
    margin: 0;
}


/* =Image
----------------------------------------------- */

.indexed.format-image .entry-header {
    min-height: 61px; /* Prevent the comment icon from colliding with the image when there is no title */
}

.indexed.format-image .entry-content {
}

.indexed.format-image p,
.indexed.format-image p img {
    margin-bottom: 0;
}

.indexed.format-image footer.entry-meta {
    margin-top: -7px;
    padding: 20px 30px;
    overflow: hidden;
}

.indexed.format-image div.entry-meta {
    display: inline-block;
    float: left;
    width: 35%;
}

.indexed.format-image div.entry-meta + div.entry-meta {
    float: none;
    width: 65%;
}

.indexed.format-image .entry-meta span.cat-links,
.indexed.format-image .entry-meta span.tag-links,
.indexed.format-image .entry-meta span.comments-link {
    display: block;
}

.indexed.format-image footer.entry-meta a {
    color: #444;
}

.indexed.format-image footer.entry-meta a:hover {
    color: #fff;
}

#content .indexed.format-image img {
    border: none;
    max-width: 100%;
    padding: 0;
}

.indexed.format-image .wp-caption {
    margin-bottom: 0;
    max-width: 96%;
    padding: 11px;
}

.indexed.format-image .wp-caption .wp-caption-text {
    color: #ddd;
}

.indexed.format-image .wp-caption .wp-caption-text:before {
    color: #444;
}

.indexed.format-image a:hover img {
    opacity: 0.8;
}


/* =error404
----------------------------------------------- */

.error404 #main #searchform {
    background: #f9f9f9;
    margin: 0 -8.9% 1.625em;
    overflow: hidden;
    padding: 1.625em 8.9%;
}

.error404 #main #s {
    width: 95%;
}

.error404 #main .widget {
    clear: none;
    float: left;
    margin-right: 3.7%;
    width: 30.85%;
}

.error404 #main .widget_archive {
    margin-right: 0;
}

.error404 #main .widget_tag_cloud {
    float: none;
    margin-right: 0;
    width: 100%;
}

.error404 .widgettitle {
    font-size: 10px;
    letter-spacing: 0.1em;
    line-height: 2.6em;
    text-transform: uppercase;
}


/* =Showcase
----------------------------------------------- */

h1.showcase-heading {
    color: #666;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 2.6em;
    text-transform: uppercase;
}

/* Intro */
article.intro {
    background: #f9f9f9;
    border-bottom: none;
    margin: -1.855em -8.9% 1.625em;
}

article.intro .entry-title {
    display: none;
}

article.intro .entry-content {
    color: #111;
    font-size: 16px;
}

article.intro .edit-link a {
    background: #aaa;
    color: #fff;
    font-size: 12px;
    padding: 0 8px;
    position: absolute;
    top: 30px;
    right: 20px;
    text-decoration: none;
}

article.intro .edit-link a:hover,
article.intro .edit-link a:focus,
article.intro .edit-link a:active {
    background: #777;
}

/* Featured post */
section.featured-post {
    float: left;
    margin: -1.625em -8.9% 1.625em;
    padding: 1.625em 8.9% 0;
    position: relative;
    width: 100%;
}

section.featured-post .hentry {
    border: none;
    color: #666;
    margin: 0;
}

section.featured-post .entry-meta {
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
}

/* Small featured post */
section.featured-post .attachment-small-feature {
    float: right;
    height: auto;
    margin: 0 -8.9% 1.625em 0;
    max-width: 59%;
    position: relative;
    right: -15px;
}

section.featured-post.small {
    padding-top: 0;
}

section.featured-post .attachment-small-feature:hover,
section.featured-post .attachment-small-feature:focus,
section.featured-post .attachment-small-feature:active {
    opacity: .8;
}

article.feature-image.small {
    float: left;
    margin: 0 0 1.625em;
    width: 45%;
}

article.feature-image.small .entry-title {
    line-height: 1.2em;
}

article.feature-image.small .entry-summary {
    color: #555;
    font-size: 13px;
}

article.feature-image.small .entry-summary p a {
    background: #222;
    color: #eee;
    display: block;
    left: -23.8%;
    padding: 9px 26px 9px 85px;
    position: relative;
    text-decoration: none;
    top: 20px;
    width: 180px;
    z-index: 1;
}

article.feature-image.small .entry-summary p a:hover {
    background: #1982d1;
    color: #eee;
    color: rgba(255, 255, 255, 0.8);
}

/* Large featured post */
section.feature-image.large {
    border: none;
    max-height: 288px;
    padding: 0;
    width: 100%;
}

section.feature-image.large .showcase-heading {
    display: none;
}

section.feature-image.large .hentry {
    border-bottom: none;
    left: 9%;
    margin: 1.625em 9% 0 0;
    position: absolute;
    top: 0;
}

article.feature-image.large .entry-title a {
    background: #222;
    background: rgba(0, 0, 0, 0.8);
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    font-weight: 300;
    padding: .2em 20px;
}

section.feature-image.large:hover .entry-title a,
section.feature-image.large .entry-title:hover a {
    background: #eee;
    background: rgba(255, 255, 255, 0.8);
    color: #222;
}

article.feature-image.large .entry-summary {
    display: none;
}

section.feature-image.large img {
    display: block;
    height: auto;
    max-width: 117.9%;
    padding: 0 0 6px;
}

/* Featured Slider */
.featured-posts {
    border-bottom: 1px dotted #ddd;
    display: block;
    height: 328px;
    margin: 1.625em -8.9% 20px;
    max-width: 1000px;
    padding: 0;
    position: relative;
    overflow: hidden;
}

.featured-posts .showcase-heading {
    padding-left: 8.9%;
}

.featured-posts section.featured-post {
    background: #fff;
    height: 288px;
    left: 0;
    margin: 0;
    position: absolute;
    top: 30px;
    width: auto;
}

.featured-posts section.featured-post.large {
    max-width: 100%;
    overflow: hidden;
}

.featured-posts section.featured-post {
    -webkit-transition-duration: 200ms;
    -webkit-transition-property: opacity, visibility;
    -webkit-transition-timing-function: ease;
    -moz-transition-duration: 200ms;
    -moz-transition-property: opacity, visibility;
    -moz-transition-timing-function: ease;
}

.featured-posts section.featured-post {
    opacity: 0;
    visibility: hidden;
}

.featured-posts #featured-post-1 {
    opacity: 1;
    visibility: visible;
}

.featured-post .feature-text:after,
.featured-post .feature-image.small:after {
    content: ' ';
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opera11.10+ */
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* IE10+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
    background: linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C */
    width: 100%;
    height: 45px;
    position: absolute;
    top: 230px;
}

.featured-post .feature-image.small:after {
    top: 253px;
}

#content .feature-slider {
    top: 5px;
    right: 8.9%;
    overflow: visible;
    position: absolute;
}

.feature-slider ul {
    list-style-type: none;
    margin: 0;
}

.feature-slider li {
    float: left;
    margin: 0 6px;
}

.feature-slider a {
    background: #3c3c3c;
    background: rgba(60, 60, 60, 0.9);
    -moz-border-radius: 12px;
    border-radius: 12px;
    -webkit-box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.5), inset 0 0 2px rgba(255, 255, 255, 0.5);
    -moz-box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.5), inset 0 0 2px rgba(255, 255, 255, 0.5);
    box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.5), inset 0 0 2px rgba(255, 255, 255, 0.5);
    display: block;
    width: 14px;
    height: 14px;
}

.feature-slider a.active {
    background: #1982d1;
    -webkit-box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.4), inset 0 0 2px rgba(255, 255, 255, 0.8);
    -moz-box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.4), inset 0 0 2px rgba(255, 255, 255, 0.8);
    box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.4), inset 0 0 2px rgba(255, 255, 255, 0.8);
    cursor: default;
    opacity: 0.5;
}

/* Recent Posts */
section.recent-posts {
    padding: 0 0 1.625em;
}

section.recent-posts .hentry {
    border: none;
    margin: 0;
}

section.recent-posts .other-recent-posts {
    border-bottom: 1px dotted #ddd;
    list-style: none;
    margin: 0;
}

section.recent-posts .other-recent-posts li {
    padding: 0.3125em 0;
    position: relative;
}

section.recent-posts .other-recent-posts .entry-title {
    border-top: 1px dotted #ddd;
    font-size: 17px;
}

section.recent-posts .other-recent-posts a[rel="bookmark"] {
    color: #373737;
    float: left;
    max-width: 84%;
}

section.recent-posts .other-recent-posts a[rel="bookmark"]:after {
    content: '-';
    color: transparent;
    font-size: 11px;
}

section.recent-posts .other-recent-posts a[rel="bookmark"]:hover {
}

section.recent-posts .other-recent-posts .comments-link a,
section.recent-posts .other-recent-posts .comments-link > span {
    border-bottom: 2px solid #999;
    bottom: -2px;
    color: #444;
    display: block;
    font-size: 10px;
    font-weight: 500;
    line-height: 2.76333em;
    padding: 0.3125em 0 0.3125em 1em;
    position: absolute;
    right: 0;
    text-align: right;
    text-transform: uppercase;
    z-index: 1;
}

section.recent-posts .other-recent-posts .comments-link > span {
    border-color: #bbb;
    color: #888;
}

section.recent-posts .other-recent-posts .comments-link a:hover {
    color: #1982d1;
    border-color: #1982d1;
}

section.recent-posts .other-recent-posts li:after {
    clear: both;
    content: '.';
    display: block;
    height: 0;
    visibility: hidden;
}


/* =Attachments
----------------------------------------------- */

.image-attachment div.attachment {
    background: #f9f9f9;
    border: 1px dotted #ddd;
    border-width: 1px 0;
    margin: 0 -8.9% 1.625em;
    overflow: hidden;
    padding: 1.625em 1.625em 0;
    text-align: center;
}

.image-attachment div.attachment img {
    display: block;
    height: auto;
    margin: 0 auto 1.625em;
    max-width: 100%;
}

.image-attachment div.attachment a img {
}

.image-attachment div.attachment a:focus img,
.image-attachment div.attachment a:hover img,
.image-attachment div.attachment a:active img {
    background: #fff;
}

.image-attachment .entry-caption p {
    font-size: 10px;
    letter-spacing: 0.1em;
    line-height: 2.6em;
    margin: 0 0 2.6em;
    text-transform: uppercase;
}


/* =Navigation
-------------------------------------------------------------- */

#content nav {
    clear: both;
    overflow: hidden;
    padding: 0 0 1.625em;
}

#content nav a {
    font-size: 12px;
    font-weight: bold;
    line-height: 2.2em;
}

#nav-above {
    padding: 0 0 1.625em;
}

#nav-above {
    display: none;
}

.paged #nav-above {
    display: block;
}

.nav-previous {
    float: left;
    width: 50%;
}

.nav-next {
    float: right;
    text-align: right;
    width: 50%;
}

#content nav .meta-nav {
    font-weight: normal;
}

/* Singular navigation */
#nav-single {
    float: right;
    position: relative;
    top: 66px;
    text-align: right;
    z-index: 5;
}

#nav-single .nav-previous,
#nav-single .nav-next {
    float: none;
    width: auto;
}

#nav-single .nav-previous {
    padding-right: 7px;
}

#nav-single .nav-next {
    padding-left: 12px;
}


/* =Widgets
----------------------------------------------- */

.widget-area {
    font-size: 12px;
}

.widget {
    clear: both;
    margin: 0;
}

.widget-title {
    color: #666;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 2.6em;
    text-transform: uppercase;
}

.widget ul {
    font-size: 15px;
    margin: 0;
}

.widget ul ul {
    margin-left: 1.5em;
}

.widget ul li {
    color: #777;
    font-size: 13px;
}

.widget a {
    font-weight: bold;
    text-decoration: none;
    color: #888;
}

.widget a:hover,
.widget a:focus,
.widget a:active {
    text-decoration: none;
}

/* Search Widget */
.widget_search form {
    margin: 0 0 1.625em;
}

.widget_search #s {
    width: 77%;
}

.widget_search #searchsubmit {
    background: #ddd;
    border: 1px solid #888;
    -webkit-box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.09);
    box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.09);
    color: #888;
    font-size: 13px;
    line-height: 25px;
    position: relative;
    top: -2px;
}

.widget_search #searchsubmit:active {
    background: #1982d1;
    border-color: #0861a5;
    -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.1);
    color: #bfddf3;
}

/* Ephemera Widget */
section.ephemera ol,
.widget_twentyeleven_ephemera ol {
    list-style: square;
    margin: 5px 0 0;
}

.widget_twentyeleven_ephemera .widget-entry-title {
    font-size: 15px;
    font-weight: bold;
    padding: 0;
}

.widget_twentyeleven_ephemera .comments-link a,
.widget_twentyeleven_ephemera .comments-link > span {
    color: #666;
    display: block;
    font-size: 10px;
    font-weight: 500;
    line-height: 2.76333em;
    text-transform: uppercase;
}

section.ephemera .entry-title .comments-link a:hover,
.widget_twentyeleven_ephemera .entry-title .comments-link a:hover {
}

section.ephemera .entry-title a span {
    color: #29628d;
}

/* Twitter */
.widget_twitter li {
    list-style-type: none;
    margin-bottom: 14px;
}

.widget_twitter .timesince {
    display: block;
    font-size: 11px;
    margin-right: -10px;
    text-align: right;
}

/* Widget Image */
.widget_image img {
    height: auto;
    max-width: 100%;
}

/* Calendar Widget */

.widget_calendar #wp-calendar {
    color: #555;
    width: 95%;
    text-align: center;
}

.widget_calendar #wp-calendar caption,
.widget_calendar #wp-calendar td,
.widget_calendar #wp-calendar th {
    text-align: center;
}

.widget_calendar #wp-calendar caption {
    font-size: 11px;
    font-weight: 500;
    padding: 5px 0 3px 0;
    text-transform: uppercase;
}

.widget_calendar #wp-calendar th {
    background: #f4f4f4;
    border-top: 1px dotted #ccc;
    border-bottom: 1px dotted #ccc;
    font-weight: bold;
}

.widget_calendar #wp-calendar tfoot td {
    background: #f4f4f4;
    border-top: 1px dotted #ccc;
    border-bottom: 1px dotted #ccc;
}


/* =Comments
----------------------------------------------- */

#comments-title {
    color: #666;
    font-size: 10px;
    font-weight: 500;
    line-height: 2.6em;
    padding: 0 0 2.6em;
    text-transform: uppercase;
}

.nopassword,
.nocomments {
    color: #aaa;
    font-size: 24px;
    font-weight: 100;
    margin: 26px 0;
    text-align: center;
}

.commentlist {
    list-style: none;
    margin: 0 auto;
    width: 68.9%;
}

.content .commentlist,
.page-template-sidebar-page-php .commentlist {
    width: 100%; /* reset the width for the one-column and sidebar page layout */
}

.commentlist > li.comment {
    background: #f6f6f6;
    border: 1px dotted #ddd;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin: 0 0 1.625em;
    padding: 1.625em;
    position: relative;
}

.commentlist .pingback {
    margin: 0 0 1.625em;
    padding: 0 1.625em;
}

.commentlist .children {
    list-style: none;
    margin: 0;
}

.commentlist .children li.comment {
    background: #fff;
    border-left: 1px dotted #ddd;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    margin: 1.625em 0 0;
    padding: 1.625em;
    position: relative;
}

.commentlist .children li.comment .fn {
    display: block;
}

.comment-meta .fn {
    font-style: normal;
}

.comment-meta {
    color: #666;
    font-size: 12px;
    line-height: 2.2em;
}

.commentlist .children li.comment .comment-meta {
    line-height: 1.625em;
    margin-left: 50px;
}

.commentlist .children li.comment .comment-content {
    margin: 1.625em 0 0;
}

.comment-meta a {
    font-weight: bold;
}

.comment-meta a:focus,
.comment-meta a:active,
.comment-meta a:hover {
}

.commentlist .avatar {
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 2px #ccc;
    -moz-box-shadow: 0 1px 2px #ccc;
    box-shadow: 0 1px 2px #ccc;
    left: -102px;
    padding: 0;
    position: absolute;
    top: 0;
}

.commentlist > li:before {
    content: url(images/comment-arrow.png);
    left: -21px;
    position: absolute;
}

.commentlist > li.pingback:before {
    content: '';
}

.commentlist .children .avatar {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    left: 2.2em;
    padding: 0;
    top: 2.2em;
}

a.comment-reply-link {
    background: #eee;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #666;
    display: inline-block;
    font-size: 12px;
    padding: 0 8px;
    text-decoration: none;
}

a.comment-reply-link:hover,
a.comment-reply-link:focus,
a.comment-reply-link:active {
    background: #888;
    color: #fff;
}

a.comment-reply-link > span {
    display: inline-block;
    position: relative;
    top: -1px;
}

/* Post author highlighting */
.commentlist > li.bypostauthor {
    background: #ddd;
    border-color: #d3d3d3;
}

.commentlist > li.bypostauthor .comment-meta {
    color: #575757;
}

.commentlist > li.bypostauthor .comment-meta a:focus,
.commentlist > li.bypostauthor .comment-meta a:active,
.commentlist > li.bypostauthor .comment-meta a:hover {
}

.commentlist > li.bypostauthor:before {
    content: url(images/comment-arrow-bypostauthor.png);
}

/* Post Author threaded comments */
.commentlist .children > li.bypostauthor {
    background: #ddd;
    border-color: #d3d3d3;
}

/* sidebar-page.php comments */
/* Make sure we have room for our comment avatars */
.page-template-sidebar-page-php .commentlist > li.comment,
.page-template-sidebar-page-php.commentlist .pingback {
    margin-left: 102px;
    width: auto;
}

/* And a full-width comment form */
.page-template-sidebar-page-php #respond {
    width: auto;
}

/* Comment Form */
#respond {
    background: #ddd;
    border: 1px dotted #d3d3d3;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin: 0 auto 1.625em;
    padding: 1.625em;
    position: relative;
    width: 68.9%;
}

#respond input[type="text"],
#respond textarea {
    background: #fff;
    border: 4px solid #eee;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: inset 0 1px 3px rgba(204, 204, 204, 0.95);
    -moz-box-shadow: inset 0 1px 3px rgba(204, 204, 204, 0.95);
    box-shadow: inset 0 1px 3px rgba(204, 204, 204, 0.95);
    position: relative;
    padding: 10px;
    text-indent: 80px;
}

#respond #comment {
    box-sizing: border-box;
    width: 100%;
    height: 252px;
}

#respond .comment-form-author,
#respond .comment-form-email,
#respond .comment-form-url,
#respond .comment-form-comment {
    position: relative;
}

#respond .comment-form-author label,
#respond .comment-form-email label,
#respond .comment-form-url label,
#respond .comment-form-comment label {
    background: #eee;
    -webkit-box-shadow: 1px 2px 2px rgba(204, 204, 204, 0.8);
    -moz-box-shadow: 1px 2px 2px rgba(204, 204, 204, 0.8);
    box-shadow: 1px 2px 2px rgba(204, 204, 204, 0.8);
    color: #555;
    display: inline-block;
    font-size: 13px;
    left: 4px;
    min-width: 60px;
    padding: 4px 10px;
    position: relative;
    top: 40px;
    z-index: 1;
}

#respond .comment-form-email label {
    padding-right: 20px;
}

#respond input[type="text"]:focus,
#respond textarea:focus {
    text-indent: 0;
    z-index: 1;
}

#respond textarea {
    resize: vertical;
    width: 95%;
}

#respond .comment-form-author .required,
#respond .comment-form-email .required {
    position: absolute;

    color: #bd3500;
    font-size: 22px;
    font-weight: bold;
    /*left: 75%;*/
    /* top: 45px; */

    top: 0;
    right: 5px;

    z-index: 1;
}

#respond .comment-notes,
#respond .logged-in-as {
    font-size: 13px;
}

#respond p {
    margin: 10px 0;
}

#respond .form-submit {
    float: right;
    margin: -20px 0 10px;
}

#respond input#submit {
    background: #222;
    border: none;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    color: #eee;
    cursor: pointer;
    font-size: 15px;
    margin: 20px 0;
    padding: 5px 42px 5px 22px;
    position: relative;
    left: 30px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
}

#respond input#submit:active {
    background: #1982d1;
    color: #bfddf3;
}

#respond #cancel-comment-reply-link {
    color: #666;
    margin-left: 10px;
    text-decoration: none;
}

#respond .logged-in-as a:hover,
#respond #cancel-comment-reply-link:hover {
    text-decoration: underline;
}

.commentlist #respond {
    margin: 1.625em 0 0;
    width: auto;
}

#reply-title {
    color: #373737;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
}

#cancel-comment-reply-link {
    color: #888;
    display: block;
    font-size: 10px;
    font-weight: normal;
    line-height: 2.2em;
    letter-spacing: 0.05em;
    position: absolute;
    right: 1.625em;
    text-decoration: none;
    text-transform: uppercase;
    top: 1.1em;
}

#cancel-comment-reply-link:focus,
#cancel-comment-reply-link:active,
#cancel-comment-reply-link:hover {
    color: #ff4b33;
}

#respond label {
    line-height: 2.2em;
}

#respond input[type=text] {
    display: block;
    height: 24px;
    width: 75%;
}

#respond p {
    font-size: 12px;
}

p.comment-form-comment {
    margin: 0;
}

.form-allowed-tags {
    display: none;
}


/* 2019/10/12
----------------------------------------------- */
/* BLOG */
input#author,
input#email,
input#url {
    box-sizing: border-box;
    width: 100% !important;
    height: 52px !important;
}


/* CONTACT */
input[name=your-name],
input[name=your-email],
input[name=your-subject] {
    width: 345px;
}



/* =Footer
----------------------------------------------- */

footer {
    width: 230px;
}

#colophon {
    position: relative;
    left: 0;
    top: 30px;
}

#colophon_home {
    position: relative;
    left: 0;
    top: -15px;
}

#supplementary {
    overflow: hidden;
}

/* Two Footer Widget Areas */
#supplementary.two .widget-area {
    float: left;
    margin-right: 3.7%;
    width: 48.1%;
}

#supplementary.two .widget-area + .widget-area {
    margin-right: 0;
}

/* Three Footer Widget Areas */
#supplementary.three .widget-area {
    float: left;
    margin-right: 3.7%;
    width: 30.85%;
}

#supplementary.three .widget-area + .widget-area + .widget-area {
    margin-right: 0;
}

/* Site Generator Line */
#site-generator {
    position: absolute;
    left: 3px;
    top: 440px;
    color: #777;
    font-size: 12px;
    line-height: 1.3em;
}

#site-generator a {
    color: #555;
    font-weight: bold;
}

#site-generator .sep {
    background: url(images/wordpress.png) center left no-repeat;
    color: transparent;
    display: inline-block;
    height: 16px;
    line-height: 16px;
    margin: 0 7px;
    width: 16px;
}


/* wp-pagenavi */
.wp-pagenavi a,
.wp-pagenavi span {
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    margin: 0 5px 0 0 !important;
    padding: 3px 8px !important;
    min-width: 32px;
}


/* =Responsive Structure
----------------------------------------------- */

@media (max-width: 800px) {
    /* Simplify the basic layout */
    #main #content {
        margin: 0;
    }
    #nav-below {
        border-bottom: 1px dotted #ddd;
        margin-bottom: 1.625em;
    }

    /* Simplify the showcase template */
    .page-template-showcase-php .featured-posts {
        min-height: 280px;
    }
    .featured-posts section.featured-post {
        height: auto;
    }
    .page-template-showcase-php section.recent-posts {
        float: none;
        margin: 0;
        width: 100%;
    }
    .page-template-showcase-php #main .widget-area {
        float: none;
        margin: 0;
        width: auto;
    }
    .page-template-showcase-php .other-recent-posts {
        border-bottom: 1px dotted #ddd;
    }
    /* Simplify the showcase template when small feature */
    section.featured-post .attachment-small-feature,
    .one-column section.featured-post .attachment-small-feature {
        border: none;
        display: block;
        float: left;
        height: auto;
        margin: 0.625em auto 1.025em;
        max-width: 30%;
        position: static;
    }
    article.feature-image.small {
        float: right;
        margin: 0 0 1.625em;
        width: 64%;
    }
    .one-column article.feature-image.small .entry-summary {
        height: auto;
    }
    article.feature-image.small .entry-summary p a {
        left: 0;
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
    }
    /* Remove the margin on singular articles */
    .singular .entry-header,
    .singular .entry-content,
    .singular footer.entry-meta,
    .singular #comments-title {
        width: 100%;
    }
    /* Simplify the pullquotes and pull styles */
    .singular blockquote.pull {
        margin: 0 0 1.625em;
    }
    .singular .pull.alignleft {
        margin: 0 1.625em 0 0;
    }
    .singular .pull.alignright {
        margin: 0 0 0 1.625em;
    }
    .singular .entry-meta .edit-link a {
        left: 0;
        position: absolute;
        top: 40px;
    }
    .singular #author-info {
        margin: 2.2em -8.8% 0;
        padding: 20px 8.8%;
    }
    /* Make sure we have room for our comment avatars */
    .commentlist {
        width: 100%;
    }
    .commentlist > li.comment,
    .commentlist .pingback {
        margin-left: 102px;
        width: auto;
    }
    /* And a full-width comment form */
    #respond {
        width: auto;
    }
    /* No need to float footer widgets at this size */
    #colophon #supplementary .widget-area {
        float: none;
        margin-right: 0;
        width: auto;
    }
    /* No need to float 404 widgets at this size */
    .error404 #main .widget {
        float: none;
        margin-right: 0;
        width: auto;
    }

}

@media (max-width: 650px) {
    /* @media (max-width: 650px) Reduce font-sizes for better readability on smaller devices */
    body, input, textarea {
        font-size: 13px;
    }
    #site-title a {
        font-size: 24px;
    }
    #site-description {
        font-size: 12px;
    }
    #access ul {
        font-size: 12px;
    }
    article.intro .entry-content {
        font-size: 12px;
    }
    .entry-title {
        font-size: 21px;
    }
    .featured-post .entry-title {
        font-size: 14px;
    }
    .singular .entry-title {
        font-size: 28px;
    }
    .entry-meta {
        font-size: 12px;
    }
    blockquote {
        margin: 0;
    }
    blockquote.pull {
        font-size: 17px;
    }
    /* Reposition the site title and description slightly */
    #site-title {
        padding: 5.30625em 0 0;
    }
    #site-title,
    #site-description {
        margin-right: 0;
    }
    /* Make sure the logo and search form don't collide */
    #branding #searchform {
        top: 1.625em !important;
    }
    /* Floated content doesn't work well at this size */
    .alignleft,
    .alignright {
        float: none;
        margin-left: 0;
        margin-right: 0;
    }
    /* Make sure the post-post navigation doesn't collide with anything */
    #nav-single {
        display: block;
        position: static;
    }
    .singular .hentry {
        padding: 1.625em 0 0;
    }
    .singular.page .hentry {
        padding: 1.625em 0 0;
    }
    /* Talking avatars take up too much room at this size */
    .commentlist > li.comment,
    .commentlist > li.pingback {
        margin-left: 0 !important;
    }
    .commentlist .avatar {
        background: transparent;
        display: block;
        padding: 0;
        position: static;
    }
    .commentlist .children .avatar {
        background: none;
        left: 2.2em;
        padding: 0;
        position: absolute;
        top: 2.2em;
    }
    /* Use the available space in the smaller comment form */
    #respond input[type="text"] {
        width: 95%;
    }
    #respond .comment-form-author .required,
    #respond .comment-form-email .required {
        left: 95%;
    }
    #content .gallery-columns-3 .gallery-item {
        width: 31%;
        padding-right: 2%;
    }
    #content .gallery-columns-3 .gallery-item img {
        width: 100%;
        height: auto;
    }

}

@media (max-width: 450px) {
    #content .gallery-columns-2 .gallery-item {
        width: 45%;
        padding-right: 4%;
    }
    #content .gallery-columns-2 .gallery-item img {
        width: 100%;
        height: auto;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    body {
        padding: 0;
    }
    #page {
        margin-top: 0;
    }
    #branding {
        border-top: none;
    }

}


/* =Print
----------------------------------------------- */

@media print {
    body {
        background: none !important;
        font-size: 13px;
    }
    footer.entry-meta a[rel=bookmark]:link:after,
    footer.entry-meta a[rel=bookmark]:visited:after {
        content: " [" attr(href) "] "; /* Show URLs */
    }
    #page {
        clear: both !important;
        display: block !important;
        float: none !important;
        max-width: 100%;
        position: relative !important;
    }
    #branding {
        border-top: none !important;
        padding: 0;
    }
    #branding hgroup {
        margin: 0;
    }
    #site-title a {
        font-size: 21pt;
    }
    #site-description {
        font-size: 10pt;
    }
    #branding #searchform {
        display: none;
    }
    #branding img {
        display: none;
    }
    #access {
        display: none;
    }
    #main {
        padding: 0;
        margin: 0;
        height: 1000px;
        border-top: none;
        box-shadow: none;
    }
    #primary {

    }
    #content {
        margin: 0;
        width: 600px;
    }
    .singular #content {
        margin: 0;
        width: 600px;
    }
    .singular .entry-header .entry-meta {
        position: static;
    }
    .entry-meta .edit-link a {
        display: none;
    }
    #content nav {
        display: none;
    }
    .singular .entry-header,
    .singular .entry-content,
    .singular footer.entry-meta,
    .singular #comments-title {
        margin: 0;
        width: 100%;
    }
    .singular .hentry {
        padding: 0;
    }
    .entry-title,
    .singular .entry-title {
        font-size: 21pt;
    }
    .entry-meta {
        font-size: 11px;
    }
    .entry-header .comments-link {
        display: none;
    }
    .page-link {
        display: none;
    }
    .singular #author-info {
        background: none;
        border-bottom: none;
        border-top: none;
        margin: 2.2em 0 0;
        padding: 0;
    }
    #respond {
        display: none;
    }
    .widget-area {
        display: none;
    }
    #colophon {
        display: none;
    }

    /* Comments */
    .commentlist > li.comment {
        background: none;
        border: 1px dotted #ddd;
        -moz-border-radius: 3px 3px 3px 3px;
        border-radius: 3px 3px 3px 3px;
        margin: 0 auto 1.625em;
        padding: 1.625em;
        position: relative;
        width: auto;
    }
    .commentlist .avatar {
        height: 39px;
        left: 2.2em;
        top: 2.2em;
        width: 39px;
    }
    .commentlist li.comment .comment-meta {
        line-height: 1.625em;
        margin-left: 50px;
    }
    .commentlist li.comment .fn {
        display: block;
    }
    .commentlist li.comment .comment-content {
        margin: 1.625em 0 0;
    }
    .commentlist .comment-edit-link {
        display: none;
    }
    .commentlist > li::before,
    .commentlist > li.bypostauthor::before {
        content: '';
    }
    .commentlist .reply {
        display: none;
    }

    /* Post author highlighting */
    .commentlist > li.bypostauthor {
        color: #444;
    }
    .commentlist > li.bypostauthor .comment-meta {
        color: #666;
    }
    .commentlist > li.bypostauthor:before {
        content: none;
    }

    /* Post Author threaded comments */
    .commentlist .children > li.bypostauthor {
        background: #fff;
        border-color: #ddd;
    }
    .commentlist .children > li.bypostauthor > article,
    .commentlist .children > li.bypostauthor > article .comment-meta {
        color: #666;
    }

}


/* =IE7
----------------------------------------------- */

#ie7 article.intro {
    margin-left: -7.6%;
    margin-right: -7.6%;
    padding-left: -7.6%;
    padding-right: -7.6%;
    max-width: 1000px;
}

#ie7 section.featured-post {
    margin-left: -7.6%;
    margin-right: -7.6%;
    max-width: 850px;
}

#ie7 section.recent-posts {
    margin-right: 7.6%;
}

/* Social button */
#social {
    line-height: 1em;
    margin-bottom: 20px;
    width: 230px;
    position: absolute;
    top: 425px;
    left: 0;
    z-index: 200;
    display: block;
}




/*
	Tablet 以下
	2019/10/01
----------------------------------------------- */

@media screen and (max-device-width: 1024px) {
    br.pc {
        display: none;
    }

    /* Flashを表示しない */
    #flashContent {
        display: none;
    }

    header#branding {
        padding: 60px 0 0 40px;
    }

    #main_home,
    #main {
        top: 170px;
    }

    nav#global {
        margin-left: 40px;
    }

    /* Footer */
    #colophon_home {
        top: -10px;
    }

    #site-generator {
        margin-left: 40px;
    }

    #colophon_home #site-generator {
        top: 490px;
    }


    /* HOME */
    #main_home .lesson_home {
        top: 270px !important;
    }

    /* PROFILE */
    img.portrait {
        width: 247px !important;
        height: auto !important;
    }

    /* CONTACT */
    input[name=your-name],
    input[name=your-email],
    input[name=your-subject],
    textarea[name=your-message] {
        width: 100%;
    }
}
